import React from 'react';
import './DownloadPage.css';
import { Helmet } from "react-helmet";
import { IoMdDownload } from "react-icons/io";  // Import download icon
import managementservices from '../assets/management_services.jpg'

const DownloadPage = () => {
    <Helmet>
        <title>Download CallQR App for free and easily manage tokens, tickets, cashbooks, events, and more!</title>

        <meta name="description" content="Download CallQR for free and easily manage tokens, tickets, cashbooks, events, and more! Stay organized, save time, and boost earnings—securely and contactlessly. Start connecting instantly with just a QR code scan." />

        <meta name="keywords" content="CallQR download, download app, QR code app, manage tokens and tickets, event management app, cashbook app, secure contactless app, free QR code contact, boost earnings, efficient QR code management" />

        <meta name="author" content="callqr.com" />

        <meta property="og:title" content="Download CallQR App for free and easily manage tokens, tickets, cashbooks, events, and more!" />
        <meta property="og:description" content="Download CallQR for free and easily manage tokens, tickets, cashbooks, events, and more! Stay organized, save time, and boost earnings—securely and contactlessly. Start connecting instantly with just a QR code scan." />


        <meta property="og:image" content="https://callqr.com/assets/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2024-09-01" />
    </Helmet>

    const handleDownload = () => {
        // Replace with your file URL or dynamic download logic
        const fileUrl = "https://play.google.com/store/apps/details?id=callqr.com";
        window.open(fileUrl, '_blank');
    };

    return (
        <div className="container_roots">
            <div class="container_size">

                <div class="title_body">

                    <div class="download_body">

                        <h1 class="title">Download CallQR App</h1>

                        <p class="description">Get started with <b>CallQR for FREE</b>—it's safe and easy to contact with just a scan!</p>

                        <p>By installing Callqr App, you agree to our <a href="termsofservice">Terms</a>  &<a href="privacypolicy">Privacy Policy.</a> </p>

                        <div class="download_div">

                            <div>Mobile/Tablet</div>

                            <div class="app_ext">Android</div>

                            <a>Minimum Requirements (Version Android 11 or above)</a>

                            <a href="https://play.google.com/store/apps/details?id=callqr.com "> <div class="play_store_google_logo"></div></a>

                        </div>

                    </div>

                    <div class="comments_body">



                        <a class="management_title">

                            CallQR: Efficiently manage tokens, tickets, cashbooks, events, and more. Stay organized, save time, and boost earnings—securely and contactlessly!</a>

                    </div>

                </div>

           

                <div class="security-features">

                    <h2 class="security-title">Three Layer Security System</h2>

                    <ul class="feature-list">

                        <li><strong>Live Location Tracking:</strong> View the caller's real-time location to ensure their authenticity.</li>

                        <li><strong>Verified Users:</strong> Only verified users can connect with you, reducing the risk of spam and fraudulent calls.</li>

                        <li><strong>No Spam Calls:</strong> Advanced algorithms filter out spam and unwanted calls, giving you peace of mind.</li>

                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DownloadPage;
