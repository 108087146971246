import React from 'react';
import './lodinging.css';
import { GrFormNextLink } from "react-icons/gr";
const Lodinging = () => {
 

  return (
    <div  className="loding-body" > 
    </div>
  );
};

export default Lodinging;
