import React from 'react';
import './ButtonWithImageboder.css'; 
import { GoDownload } from "react-icons/go";
const ButtonWithImageboder = ({ 
  title = "Boost Your Productivity", 
  buttonText = "Get Started",
  buttonLink = "#"  // ✅ Fixed assignment syntax
}) => {

  const handleClick = () => {
    window.location.href = buttonLink;    // Redirect to button link on click
  };

  return (
    <div onClick={handleClick} className="custom-boder-button" title={title}>
      <span className="button-text">{buttonText}</span>
        <GoDownload className="icon button-icon" />
    </div>
  );
};

export default ButtonWithImageboder;
