import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import homescannedalert from '../assets/homescannedalert.jpg';
import './Homedatawhoitwork.css';  // Import the CSS file

const Homedatawhoitwork = () => {
  const [visible, setVisible] = useState(false);

  // Trigger animation immediately on component mount
  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="show-case-body">
      <motion.div
        className="animated-box"
        initial={{ opacity: 0, y: 50 }}
        animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
      >
        <div className="container_size">
          {/* Title Section */}
          <div className="title-div">
            <div className="title-text">Scanned Alert</div>
            <div className="desc-text">
              With CallQR, you’re not just choosing a service—you’re choosing excellence,<br />
              <strong> Reliability, and peace of mind.</strong>
            </div>
          </div>

          {/* Showcase Section */}
          <div className="show-case">
            
            {/* Left Content */}
            <div className="content">
              <h2>Real-Time Caller Information</h2>

              <p>
                Experience unparalleled security and convenience by choosing CallQR for all your communication needs. 
                Here's how CallQR works for you:
              </p>

              <ul>
                <li><strong>Quick and Easy Setup:</strong> Place a CallQR Tag on your gadgets, belongings, or business cards. 
                  Anyone who scans the QR code can easily contact you, with your phone number and details securely managed through our platform.
                </li>

                <li><strong>Real-Time Caller Information:</strong> Instantly see the caller's real name, location, and other 
                  identity details. Be fully informed before picking up any call.
                </li>

                <li><strong>Access Shared Information:</strong> Have access to all the information shared via the QR code, 
                  allowing you to communicate effectively and safely.
                </li>
              </ul>

              <p>
                Don't compromise on security. Download the CallQR app today and place a CallQR Tag on your devices. 
                Enjoy secure, seamless communication where you control who can contact you. 
                <strong>Stay informed, stay protected with CallQR!</strong>
              </p>
            </div>

            {/* Right Image */}
            <div className="image-container">
              <img
                src={homescannedalert}
                alt="Scanned Alert"
                className="image"
              />
            </div>

          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Homedatawhoitwork;
