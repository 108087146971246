import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PiFolderSimpleLockLight } from "react-icons/pi";
import { IoLinkOutline } from "react-icons/io5";
import { IoQrCodeOutline } from "react-icons/io5";
import "./design_menu.css";

export default function Designmenu() {
    const navigate = useNavigate();
    const location = useLocation(); // Get current route

    return (
        <div className="design-menu">
            <div className="button-body">
                <div 
                    onClick={() => navigate('/')} 
                    className={`design-menu-button ${location.pathname === '/' ? 'active' : ''}`} 
                    title="QR Code"
                >
                    <IoQrCodeOutline className="menubutton-icon" />
                    <span className="button-text">QR Code</span>                         
                </div>  
                <div 
                    onClick={() => navigate('/short-url')} 
                    className={`design-menu-button ${location.pathname === '/short-url' ? 'active' : ''}`} 
                    title="Short URL"
                >
                    <IoLinkOutline className="menubutton-icon" />
                    <span className="button-text">Short URL</span>                         
                </div>  
            </div> 
        </div>
    );
}
