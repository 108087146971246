import './App.css';
import { Route, Routes } from "react-router-dom";
import Header from './body_roots/header';
import Footer from './body_roots/footer';
import Home from './pages/home';
import Contactus from './pages/contact-us';
import DownloadPage from './pages/download';
import Termsofservice from './pages/terms-of-service';
import Helpcenter from './pages/help-center';
import Privacypolicy from './pages/privacy-policy';
import Disclaimer from './pages/disclaimer';
import Sitemap from './pages/sitemap';
import Forbusiness from './pages/for-business';
import Price from './pages/price';
import Features from './pages/features';
import Design from './pages/design';
import Security from './pages/security';
import Userguide from './pages/user_guide';
import Shorturl from './design_root/short-url';
import Order from './data_pages/order';
function App() {
  return (
    <div className="App">
      <Header />  
      <div className='app_header_height'></div>        
      <Routes>
        <Route path="/" element={<Home />} />  
        <Route path="/short-url" element={<Shorturl />} /> 
        <Route path="/order" element={<Order />} /> 
        <Route path="/features" element={<Features />} />  
        <Route path="/price" element={<Price />} /> 

        <Route path="/design" element={<Design/>} /> 
        <Route path="/contact-us" element={<Contactus />} /> 
        <Route path="/for-business" element={<Forbusiness />} />
        <Route path="/download" element={<DownloadPage />} /> 
        <Route path="/help-center" element={<Helpcenter />} /> 
        <Route path="/terms-of-service" element={<Termsofservice />} /> 
        <Route path="/privacy-policy" element={<Privacypolicy />} /> 
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/security" element={<Security />} />
        <Route path="/user_guide" element={<Userguide />} />
        {/* 
        this user data page add in last */}
        <Route path="/:id" element={<Home />} />  
      </Routes> 
      <Footer />
    </div>
  );
}

export default App;
