import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { db } from "../firebase"; 
import Logo from '../assets/callqr_logo.png' 
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
 
import { getFirestore, doc, setDoc, getDoc, updateDoc, collection } from "firebase/firestore";
import './App_header.css'; 
import photoicon from  '../assets/photos_icon.png';
import Phonelogin from '../pages/phonelogin';
import '../components/ButtonWithImage';  
import { GrFormNextLink } from "react-icons/gr";
import { HiMenuAlt2 } from "react-icons/hi";
import ButtonWithImageboder from '../components/ButtonWithImageboder';
import { FaArrowUp } from 'react-icons/fa';  // For arrow icon
import { IoIosAdd } from "react-icons/io";
import MobileMenu from '../pages/MobileMenu';
import { MdOutlineAddBusiness } from "react-icons/md";

export default function Header() {
    const [isVisible, setIsVisible] = useState(false);
    const messageRef = useRef();
    const navigate = useNavigate(); 
    const [user, setUser] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [accountguid, setaccount] = useState(null); // Start with null instead of []
    const [loginisOpen, loginsetIsOpen] = useState(false);   // State for popup
 
    const [photourl, userphotourl] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            const existingUser = Cookies.get("guid"); // Check for existing login
            
            if (existingUser) {
            
                loginsetIsOpen(true);
                
                const userRef = doc(db, "account", existingUser);
                const docSnap = await getDoc(userRef);
                
                if (docSnap.exists()) {
                    userphotourl(docSnap.data().photo); // ✅ Correct way to access Firestore data
                }
            } else {
                loginsetIsOpen(false);
            }
        };

        fetchUserData(); // ✅ Call the async function

    }, []);  
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    const homeClick = () => {
      navigate('/');  // Navigate to another route
      scrollToTop();
    };
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
       // Show button when scrolling down
       useEffect(() => {
        const toggleVisibility = () => {
            if(window.scrollY > 300)
            {
                setIsVisible(true);
                const headerElement = document.getElementsByClassName('App_header')[0]; 
                if (headerElement) {
                    headerElement.style.WebkitBoxShadow = "0px 5px 5px -4px rgba(0,0,0,0.14)";  
                    headerElement.style.MozBoxShadow = "0px 5px 5px -4px rgba(0,0,0,0.14)";
                    headerElement.style.boxShadow = "0px 5px 5px -4px rgba(0,0,0,0.14)";     
                }
            }
            else{ 
                setIsVisible(false);
                const headerElement = document.getElementsByClassName('App_header')[0];
                if (headerElement) {
                    headerElement.style.WebkitBoxShadow = "0px 5px 5px -4px rgba(0,0,0,0.0)";  
                    headerElement.style.MozBoxShadow = "0px 5px 5px -4px rgba(0,0,0,0.0)";
                    headerElement.style.boxShadow = "0px 5px 5px -4px rgba(0,0,0,0.0)";   
                }
            }
        };
        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
const Settings = () =>{

}
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const designClick = () => {
        navigate('/design');  // Navigate to another route
        
      };
    return (
    
        <div className='App_header'>
          <div className='left_div'>
     
          <HiMenuAlt2 onClick={toggleMenu} className='icon menu-btn' />
          <img src={Logo} onClick={homeClick} className='logo'></img> 
         
          
          </div>
          <div className='center_body'> 
            <div className='menu_body'>
            
          <a href="/features" className="link_btn">Features</a>                    
                    <a href="/price" className="link_btn">Price</a>
                    <a href="/help-center" className="link_btn">Help</a>
          </div>
          </div>
         
            <div className='right_div'> 
                <div className='download-icon'>
            <ButtonWithImageboder 
    title="Submit your Query"
    buttonText="Download"  
    buttonLink="download"
/></div>

{loginisOpen ? (              
    <img className="login_settings" onClick={Settings} src={photourl} alt="User Profile" />     
) : (
    <div onClick={togglePopup} className="login-icon" title="Login">
        <span className="button-text">Log In</span>
        <GrFormNextLink className="icon" />
    </div>
)}

  
            </div>
            {showPopup && (
                <div className="phone-login-popup-overlay">
                    <div className="popup-box">
                  
                        <Phonelogin />
                        <div onClick={togglePopup} className='phone-close-button'>Cancel</div>
                    </div>
                </div>
            )}
              <div>
            {isVisible && (
                <button onClick={scrollToTop} className="scroll-to-top-btn" title="Scroll to top">
                    <FaArrowUp />
                </button>
            )}
        </div>
        </div>
    );
}
