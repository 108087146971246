import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./home_data.css";
import { MdOutlineAddBusiness } from "react-icons/md";
import Homedataad from './home_data_ad'
import Homedataproducts from './home_data_products';
import Homedatainformation from './home_data_information';
import Homedatascannedalert from "./home_data_scanned_alert";
import Homedatawhoitwork from "./home_data_how_it_work";
import Ourfounders from "./our_founders";

import Designqrcode from "../design_root/design-qrcode";

export default function Homedata() {
  
 
  return (
    <div >
      <Helmet>
    
     
        <title>Design QR Codes | Generate Free QR Codes Online | Vehicle Parking Tag | QR Code Creator | Scan Any QR, Anytime, Anywhere! Download CallQR App</title>
        <meta name="description" content="Effortlessly create and manage QR codes with CallQR. Generate free custom QR codes for websites, parking tags, events, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!" />
        <meta name="keywords" content="Free QR Code Generator, QR Code Creator Online, Vehicle Parking Tag QR, Custom QR Code Maker, Scan QR Codes Instantly, CallQR App Download, Create Event QR Tags, Business Tools with QR, ID Card QR Generator, Secure Spam-Free Calls, Location Tracking with QR, Attendance Management QR, Parking Management QR, QR Codes for Membership Cards, QR Tags for Events and Business" />
        <meta name="author" content="callqr.com" />

 
        <meta property="og:title" content="Design QR Codes | Generate Free QR Codes Online | Vehicle Parking Tag | QR Code Creator | Scan Any QR, Anytime, Anywhere! Download CallQR App"/>

<meta property="og:description" content="Effortlessly create and manage QR codes with CallQR. Generate free custom QR codes for websites, parking tags, events, and more. Scan any QR, anytime, anywhere. Download the CallQR app now!"/>
<meta property="og:keywords" content="Free QR Code Generator, QR Code Creator Online, Vehicle Parking Tag QR, Custom QR Code Maker, Scan QR Codes Instantly, CallQR App Download, Create Event QR Tags, Business Tools with QR, ID Card QR Generator, Secure Spam-Free Calls, Location Tracking with QR, Attendance Management QR, Parking Management QR, QR Codes for Membership Cards, QR Tags for Events and Business" />
      
       
        <meta property="og:image" content="https://callqr.com/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2023-09-01" />
      </Helmet>
      <div className="container_roots"> 
 
<Designqrcode/>

      </div>
 
      <div> 
         
      <Homedataad />    
      <Homedatainformation/>
      <Homedataproducts/>
      <Homedatascannedalert/>
      <Homedatawhoitwork/>
      <Ourfounders/>
      </div>
    </div>
  );
}
