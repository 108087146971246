import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import  ButtonWithImage from '../components/ButtonWithImage';
export default function Design() {
  return (
    <div className="container_roots">
  
      <Helmet>
        <title>Design QRCodes | Generate Free QR Codes Online | QR Code Creator | Scan Any QR, Anytime, Anywhere! download Callqr app</title>
        <meta name="description" content="CallQR.com | Design QR codes , Generate free QR codes instantly. Create custom QR codes for websites, text, or contact information." />
        <meta name="keywords" content="CallQR,Design QRCodes, secure communication, vehicle parking, QR tags, event cards, business tools, membership, attendance, spam-free calls" />
        <meta name="author" content="callqr.com" />

 
        <meta property="og:title" content="Design QRCodes | Generate Free QR Codes Online | QR Code Creator | Scan Any QR, Anytime, Anywhere! download Callqr app"/>

<meta property="og:description" content="CallQR.com | Design QR codes , Generate free QR codes instantly. Create custom QR codes for websites, text, or contact information"/>

<meta property="og:keywords" content="Design QRCodes, Free QR Code Generator, QR Code Creator, QR Code Maker, Generate QR Codes Online"/>
        <meta property="og:image" content="https://callqr.com/assets/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2024-09-01" />
      </Helmet>
      <div className="container_size">
      <h1 className="contact-title">free-qrcode-generator</h1>
      </div>
</div>
    );
}
