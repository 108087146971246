import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { redirect, useParams } from "react-router-dom";
import Userdata from "../data_pages/user_data";
import Homedata from "../data_pages/home_data";
import Lodinging from "../components/lodinging";
export default function Home() {
  const { id } = useParams();
  const [docData, setDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setLoading(false);
        return;
      }

      try {
        
        // Fetch document from Firestore
        const newDocRef = doc(db, "callqr", id);
        const newDocSnap = await getDoc(newDocRef);

        if (newDocSnap.exists()) {
          setDocData(newDocSnap.data());
        } else {
          setLoading(false);
        return;
        }
      } catch (error) {
        console.error("Error fetching document:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="container_roots">
      <Lodinging/>
      </div>
    );
  }
  return (
<div className="contact_content">


      {id && !error && docData ? (
        <Userdata data={docData} />
      ) : (
        <Homedata />
      )}
    </div>
  );
}
