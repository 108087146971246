import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet"; 
import  ButtonWithImage from '../components/ButtonWithImage';
export default function Disclaimer() {
  return (
    <div className="container_roots">
  
      <Helmet>
        <title>Get Started for FREE with CallQR | Scan to Contact, Vehicle Parking Tag, Manage In & Out Entries, Membership & Attendance</title>
        <meta name="description" content="CallQR.com | Manage in and out entries effortlessly! Create parking cards, track caller locations, and view verified details. Use QR tags for ID cards, event cards, business tools, membership, attendance, and more. Download CallQR for secure, spam-free calls!" />
        <meta name="keywords" content="CallQR, secure communication, vehicle parking, QR tags, event cards, business tools, membership, attendance, spam-free calls" />
        <meta name="author" content="callqr.com" />

 
        <meta property="og:title" content="Get Started for FREE with CallQR | Fast, Secure & Easy to Contact" />
        <meta property="og:description" content="CallQR.com offers fast, secure, and easy communication. Track caller locations, view verified details, and share contact info seamlessly with a QR Tag. Download CallQR today for safe, spam-free calls." />
        <meta property="og:image" content="https://callqr.com/img/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2024-09-01" />
      </Helmet>
      <div className="container_size">
      <h1 className="contact-title">Disclaimer</h1>
      </div>
</div>
    );
}
