import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import './user_data.css';
import { MdOutlineVerifiedUser } from "react-icons/md";
import { BsGenderAmbiguous } from "react-icons/bs";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineCalendarDateRange } from "react-icons/hi2";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { IoShareSocial } from "react-icons/io5";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { SlSocialYoutube } from "react-icons/sl";
import { SlSocialPintarest } from "react-icons/sl";
import { SlSocialLinkedin } from "react-icons/sl";
import { SlSocialTumblr } from "react-icons/sl";
import { FaPhone } from "react-icons/fa6";
import Lodinging from "../components/lodinging";
export default function Userdata({ data }) {
  const [docData, setDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (data?.guid) {
        try {
          const docRef = doc(db, "account", data.guid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setDocData(docSnap.data());
            console.log("Document exists:", docSnap.data());
          } else {
            console.log("Document does not exist.");
            setDocData(null);
          }
        } catch (error) {
          console.error("Error fetching document:", error);
          setError(true);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false); // No GUID, stop loading
      }
    };

    fetchData();
  }, [data?.guid]);

  if (!data) {
    return (
      <div className="container_roots">
        <h2>No User Data Found</h2>
      </div>
    );
  }  
  const share_btn = () => {
    const currentUrl = window.location.href;

    if (navigator.share) {
        navigator.share({
            title: document.title,
            url: currentUrl
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
        // Fallback for browsers that do not support Web Share API
        const tempInput = document.createElement("input");
        tempInput.value = currentUrl;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("URL copied to clipboard!");
    }
};
  return (
    <div className="container_roots">
      {loading ? (
       <Lodinging/>
      ) : error ? (
        <p>Error fetching data.</p>
      ) : (
        <div className="container_card">
          <div className="user-card">
            <div className="photo-div">
              <img className="photo-img" src={docData?.photo} alt="User" />
              <MdOutlineVerifiedUser className="Verified" />
            </div>
            <div className="name-div">
              <div className="name-text">{docData?.name}</div>
              <div className="status-text">{docData?.about}</div>
             
            </div>
          </div>
          <div className="user-card">
          <div className="phone-div">
              <FaPhone className="icon phone-icon" /><b className="phone-text">+{docData?.phone}</b>
              </div>
              </div>
          <div className="user-card">
            <div className="gender-div" >
              <BsGenderAmbiguous className="icon" />
              <div className="gender-text">{docData?.gender}</div>
            </div>
            <div className="dob-div" >
              <LiaBirthdayCakeSolid className="icon" />
              <div className="gender-text">
                {docData?.date_of_birth?.toDate().toLocaleDateString('en-GB') ?? "N/A"}
              </div>
            </div>
          </div>
         
          <div className="user-card">
            <table >
              <tr><td>
                <IoLocationOutline className="icon adds-icon" />
                <div className="address-text">
                  {docData?.address}</div>
              </td></tr>
            </table>
          </div>
          <div className="user-card">
            <div className="row_verifed" >
              <MdOutlineSupervisorAccount className="icon" />
              <div className="verifed-text">
                <b>2</b> Verifed
              </div>
            </div>
            <div className="row_fill" > 
              <div className="joined-text">Joined <b>
                {docData?.date_time?.toDate().toLocaleDateString('en-GB') ?? "N/A"}</b>
              </div>
            </div>

          </div>
          <div className="media-card">
          <TiSocialFacebook className="media-link" /> 
          <TiSocialInstagram className="media-link" /> 
          <SlSocialYoutube className="media-link" />  
          <SlSocialLinkedin  className="media-link" /> 
          <SlSocialPintarest  className="media-link" />     
          <SlSocialTumblr  className="media-link" />          
          <IoShareSocial onClick={share_btn} className="media-link" /> 
 
          </div>
        </div>
      )}
    </div>
  );
}
