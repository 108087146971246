 
import { initializeApp } from "firebase/app";   // ✅ Only one import
import Cookies from 'js-cookie';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
 

const firebaseConfig = {
  apiKey: "AIzaSyBBrDds_05fvxejfzmm5gm4ywDQIlEO6E4",
  authDomain: "callqr-ab941.firebaseapp.com",
  databaseURL: "https://callqr-ab941-default-rtdb.firebaseio.com",
  projectId: "callqr-ab941",
  storageBucket: "callqr-ab941.appspot.com",
  messagingSenderId: "331322470394",
  appId: "1:331322470394:web:4ebc4a4f1d769c0ef2fe43",
  measurementId: "G-H8XG5726FS"
}; 
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
 
export { auth, db, RecaptchaVerifier, signInWithPhoneNumber, signOut };