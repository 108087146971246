import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; 
const Homedataad = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const triggerPosition = 300; // Trigger animation at 300px
      setVisible(scrollPosition > triggerPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div >    
      
            <div className='container_size'>
            <div class="title-div">
         <div class="title-text">
            CallQR – One Scan, Instant Access to Everything!
         </div>
         <div className="desc-text">
      Imagine a world where sharing contact details, verifying identities, and managing interactions is as easy as
      scanning a QR code. <br /> 
      <strong>CallQR makes it possible!</strong> No more exchanging numbers, saving contacts, or worrying
      about privacy—just scan & connect.
    </div>
      </div>
      </div>
       
    </div>
  );
};
const styles = {
  showCaseBody: {
    padding: '50px 20px',
    margin: '0px 0px'
  }
};
export default Homedataad;
