import React from "react";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { Helmet } from "react-helmet";
import "./Contactus.css";  // Import the CSS file
import  ButtonWithImage from '../components/ButtonWithImage';
export default function Contactus() {
  return (
    <div className="container_roots">
  
      <Helmet>
        <title>Get Started for FREE with CallQR | Scan to Contact, Vehicle Parking Tag, Manage In & Out Entries, Membership & Attendance</title>
        <meta name="description" content="CallQR.com | Manage in and out entries effortlessly! Create parking cards, track caller locations, and view verified details. Use QR tags for ID cards, event cards, business tools, membership, attendance, and more. Download CallQR for secure, spam-free calls!" />
        <meta name="keywords" content="CallQR, secure communication, vehicle parking, QR tags, event cards, business tools, membership, attendance, spam-free calls" />
        <meta name="author" content="callqr.com" />

 
        <meta property="og:title" content="Get Started for FREE with CallQR | Fast, Secure & Easy to Contact" />
        <meta property="og:description" content="CallQR.com offers fast, secure, and easy communication. Track caller locations, view verified details, and share contact info seamlessly with a QR Tag. Download CallQR today for safe, spam-free calls." />
        <meta property="og:image" content="https://callqr.com/img/callqr_icon.png" />
        <meta property="og:url" content="https://callqr.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:dateModified" content="2024-09-01" />
      </Helmet>

      <h1 className="contact-title">Contact Us</h1>

 

  <div  className="container_size">
  {/* Left Section - Contact Info */}
  <div className="contact-info">
    <div className="info-box">
      <FaPhone className="icon" />
      <b>Phone</b>
      <p>+91 95306 21728</p>
    </div>

    <div className="info-box">
      <FaEnvelope className="icon" />
      <b>Email</b>
      <p>support@callqr.com</p>
    </div>

    <div className="info-box">
      <FaMapMarkerAlt className="icon" />
      <b>Address</b>
      <p>192 Defence Colony, Malerkotla, Punjab, India</p>
    </div>
  </div>

  {/* Right Section - Contact Form */}
  <div className="contact-form">
    <form>
      <div className="form-group">
        <label>Name</label>
        <input type="text" placeholder="Your Name" required />
      </div>

      <div className="form-group">
        <label>Email</label>
        <input type="email" placeholder="Your Email" required />
      </div>

      <div className="form-group">
        <label>Message</label>
        <textarea placeholder="Your Message" rows="5" required></textarea>
      </div>

      <ButtonWithImage 
title="Submit your Query"
  buttonText="Submit" 
  buttonLink="https://callqr.com"
/>
    </form>
  </div>
 </div>

{/* Google Map */}
<div  className="container_size">
<div className="map-container">
  <iframe
    title="Google Map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.3659263920396!2d75.89129617556526!3d30.526644698688536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a94c0f5e8b7b1%3A0x4eb7e67c817b0d1!2sMalerkotla%2C%20Punjab!5e0!3m2!1sen!2sin!4v1711539331315!5m2!1sen!2sin"
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
 
</div>
    </div>
    </div>
  );
}
