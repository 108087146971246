import React from 'react';
import './ButtonWithImage.css';
import { GrFormNextLink } from "react-icons/gr";
const ButtonWithImage = ({ 
  title = "Boost Your Productivity", 
  buttonText = "Get Started",
  buttonLink = "#" // ✅ Fixed assignment syntax
}) => {

  const handleClick = () => {
    window.location.href = buttonLink;    // Redirect to button link on click
  };

  return (
    <div onClick={handleClick} className="custom-button" title={title}>
      <span className="button-text">{buttonText}</span>
            <GrFormNextLink className="icon button-icon" />
    </div>
  );
};

export default ButtonWithImage;
